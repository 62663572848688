.oneThousand {
    position: absolute;
    cursor: pointer;
    width: 100%;
    left: 0px;
    font-family: 'Ubuntu', sans-serif;
    background-color: #24294B;
    text-align: center;
    color: white;
    padding: 10px 15px 10px 15px;
    //margin-top: 40px;
}

.MuiDialogContent-root.dialogContent_2K {
    padding: 14px;
}

@media only screen and (max-width: 475px) {
    .MuiDialogContent-root:first-child.dialogContent_2K {
        padding: 8px;
    }
}

/* .promoBanner {
    //grid-area: a;
    position: absolute;
    width: 100%;
    left: 0px;
    font-family: 'Ubuntu', sans-serif;
    background-image: linear-gradient( 90deg, rgba(253,83,146,0.99608) 0%, rgb(248,111,100) 100%);
    text-align: center;
    color: white;
    padding: 10px 15px 10px 15px;
} */

/*
@media only screen and (max-width: 987px) {
    .oneThousand {
        //margin-top: 60px;
    }

    .appInfo{
        //padding-top:140px;
    }
}

@media only screen and (max-width: 517px) {
    .oneThousand {
        //margin-top: 80px;
    }

    .appInfo{
        //padding-top:160px;
    }
}

@media only screen and (max-width: 365px) {
    .oneThousand {
        //margin-top: 100px;
    }

    .appInfo{
        //padding-top:180px;
    }

    div.MuiDialogContent-root.dialogContent {
        //padding: 8px;
    }
}
*/