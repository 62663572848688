.inactive {
    background-color: #fbe39e;
    color: grey;
    font-weight: bold;
    /*height: 2rem;*/
    width: 100%;
    padding: 10px 10px 10px 20px;
    margin-bottom: 10px;
    border-radius: 2px;
}
  
.postContainer {
    display: grid;
    grid-template-columns: 1fr 1fr; /*grid-template-columns: 50% 50%;*/
    grid-template-rows: auto auto; /*grid-template-rows: 50% 50%;*/
    gap: 10px 10px; /*causes overflow*/
    /*max-block-size: 100% 354px;*/
	margin-bottom: 40px; /**/
	
	#itemImg {
		height: 300px;
		width: auto; /*width: 200px; width: 100%;*/
		max-width: 100%; /*max-width: 400px;*/
		overflow: hidden;
	}

	.carousel {

		.slide {
			background-color: #fcfcfc;
			padding-top: 1px;
			padding-bottom: 1px;
			border: 1px solid rgb(221, 219, 219);
		
			max-width: 0; /*solve problem of overwide images*/

			img {
				height: 300px;
				width: auto;
				/*max-width: 460px;*/ /*max-width: 523px;*/
				overflow: hidden; /**/
			}
		}

		.carousel-status {
			margin-right: 10px;
			color: grey;
			text-shadow: 1px 1px 1px #fff;
		}

		.thumb {
			background-color: #f9f9f9;
			border: #f9f9f9; /**/

			img {
				height: 40px;
				width: auto;
				border-radius: 1px;
			}
		}

		li.thumb {
			width: auto !important /**/
		}

		.thumb.selected {
			border: 1px solid rgb(182, 182, 182);
			border-radius: 1px;
		}

		.thumb:hover {
			border: 1px solid rgb(145, 144, 144);
			border-radius: 1px;
		}

		.thumbs-wrapper {
			margin-top: 10px;
			margin-bottom: 0px;
		}

		ul.thumbs {
			display: flex;
			justify-content: center;
		} /**/ /*does this break anything?*/
	}

	.imgContainer {
		padding-top: 5px;
	}
}

  /*.carousel .slide {
    background-color: #f9f9f9;
    border-left: 2px solid rgb(221, 219, 219);
    border-right: 2px solid rgb(221, 219, 219);
  }*/
  
  
  /*
  .postContainer .image .imageContainer #itemImg {
    max-width: 460px;
    overflow: hidden;
  }
  */
  
@media only screen and (max-width: 768px){
    .postContainer {
      display: grid;
      grid-template-columns: 100%;
	  grid-template-rows: auto auto auto 400px;
	  
	  /*stock image*/
	  #itemImg {
			height: 200px;
			width: auto;
			margin-bottom: 5px;
			/* max-width: 523px; */
			/* overflow: hidden; */
		  }
		  
		/*user images*/
		.carousel .slide img {
			height: 200px;
			width: auto;
			/* max-width: 523px; */
			/* overflow: hidden; */
		}
	} 
}
  
.carousel button {
    height: auto;
    width: auto;
}
  
.CarouselItem div {
	border: 1px solid #f9f9f9;
	background-color: #f9f9f9;
}
  
div.Carousel-indicators-6 {
    margin-top: 5px;
}
  
#itemImg {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
  
/*
.itemInfo, .userInfo, .postDescription, .postDetails {
display: inline;
}*/
  
.title {
	margin-bottom: 10px;
}
  
.bottom {
	margin-top: 10px
}
  
#item {
    font-size: 25px;
}
  
.postInfo button {
	margin-bottom: 10px;
}
  
.postInfo button {
    width: 100%;
    background-color: rgb(60, 206, 105);
    font-family:"Helvetica Neue",sans-serif;
    font-weight: bold;
    font-size: 15px;
    text-transform: initial;
    color: white;
    border-radius: 4px;
    border-color: rgb(60, 206, 105);
    cursor: pointer;
}
  
.postInfo button:focus, .userInfo button:focus {
    outline: 0;
    text-shadow: 1px 2px grey;
}
  
.postInfo button.inButton{
    color: grey;
    background-color: rgb(207, 207, 207);
    border-color: rgb(207, 207, 207);
}
  
.userImgName {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.userImgName:hover {
	cursor: pointer;
	color: green;
}

.assocImgName:hover {
	cursor: pointer;
	color: green;
}

.assocUser {
	/* display: flex;
	flex-direction: row;
	justify-content: space-evenly; */
	display: grid;
    grid-template-columns: 1fr 1fr;

	p {
		font-size: 15px;
	}

	.assocImgName {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		margin-top: 10px;
		margin-bottom: 10px;
		margin-right: 10px;

		img {
			border-radius: 100%;
		}

		.nameStar {
			margin-top: auto;
			margin-bottom: auto;
			margin-left: 10px;
		}
	}

	.userImgName {
		img, .name {
			margin-top: auto;
			margin-bottom: auto;
		}

		img {
			margin-right: 10px;
		}

		p#name {
			max-width: 150px;
		}
	}

}

@media only screen and (max-width: 768px){
	.assocUser {
		display: flex;
		flex-direction: column;

		.userImgName {
			img {
				margin-left: 8px;
			}

			p#name {
				max-width: none;
			}
		}
	}
}
  
#userImg {
    border-radius: 100%;
}
  
.nameStar {
    margin-left: 20px;
}
  
.textOverflow {
    overflow: scroll;
    text-overflow: clip;
    max-height: 100px;
}
  
.textOverflow::-webkit-scrollbar {
    width: 6px;
    height: auto;
}
    
.textOverflow::-webkit-scrollbar-thumb {
    background: grey;  
    border-radius: 10px;
}
  
  /**/ /**/ /**/ 
  
.itemInfo, .postDescription {
    margin-bottom: 5px;
}
  
.postInfo {
    display: flex;
    flex-direction: column;
}
  
.postInfo .itemInfo {
	flex-grow: 2;
	display: flex;
	flex-direction: column;
}
  
.postInfo .itemInfo .bottom {
	margin-top: auto;
}

.profileUserDialog {

	.circle {
		height: 20px;
		width: 20px;
		background-color: rgb(2, 154, 255);
		border-radius: 100%;
		opacity: 0.4;
		border: 2px solid rgb(2, 154, 255);
	}

	.profileImg {
		width: 80px;
		height: 80px;
		border-radius: 100%;
	}

	.starRating {
		display: flex;
		flex-direction: row;

		p {
			font-size: 15px;
			margin-left: 10px;
			margin-top: 3px;
		}
	}

	.sendMessage {
		font-size: 15px;
		line-height: 2;

		display: flex;
		flex-direction: row;
		align-items: center;

		width: 200px;

		color: rgb(145, 144, 144);
		

		:first-child {
			margin-right: 10px;
			margin-top: 5px;
		}

	}

	.sendMessage:hover {
		color: black;
		cursor: pointer;
	}

	.bio {
		font-size: 15px;
		margin-top: 5px;
		margin-bottom: 10px;
	}

	/* .map {
		//margin-top: 5px;
	} */

	.roles, .livePosts {
		line-height: 2;
	}

	.roleDesc {

		.role {
			line-height: 1.5;
		}

		margin-bottom: 5px;

		.roleItems {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		
			
			margin-bottom: 5px;
		
			.roleItem {
				display: flex;
				flex-direction: row;
		
				background-color: rgb(207, 207, 207);
				border-radius: 20px;
		
				font-size: 15px;
		
				margin-bottom: 3px;
				margin-right: 4px;
		
				img {
					width: 20px;
					height: 20px;
					border-radius: 100%;
		
					margin: 3px;
				}

				.MuiSvgIcon-root {
					width: 20px;
					height: 20px;

					margin: 3px;
				}
		
				p {
					margin-top: auto;
					margin-bottom: auto;
					margin-left: 4px;
					margin-right: 8px;
					
				}
				//text-align: center;
			}
		}
		.vehicleDesc {
			font-size: 15px;
			padding: 4px;
			margin-bottom: 10px;
			border-radius: 3px;
			border: 1px solid rgb(207, 207, 207);
		}
	}

	.livePosts {

		margin-bottom: 10px;

		.livePost {

			font-size: 15px;
			color: black;
			line-height: 1;

			cursor: pointer;

			margin-bottom: 5px;

			> p {
				font-weight: 500;
			}

			

			.imgVolPrice {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				max-width: 400px;

				margin-bottom: 4px;
				
				div :first-child{
					color:rgb(145, 144, 144)
				}

				.volValue {
					margin-right: 10px;
				}
			}
		}

		img {
			width: 40px;
			height: 40px;
			border-radius: 100%;

			margin-left: 10px;
			margin-right: 10px;
		}

		.livePost:hover {
			color: green;

			.imgVolPrice {			
				div :first-child{
					color:green;
				}
			}
		}
	}
}

.orgType {
	margin-top: 5px;
	margin-bottom: 5px;
}

.assocTel, .orgType {
	display: flex;
	flex-direction: row;
	align-items: center;

	:first-child {
		color: rgb(145, 144, 144);
		font-size: 20px;

		margin-right: 10px;
	}

	p {
		font-size: 13px;
	}
}

.orgOwner {
	display: flex;
	flex-direction: row;
	align-items: center;
	img {
		width: 50px;
		height: 50px;
		border-radius: 100%;

		margin-right: 10px;
	}
}

/* .orgOwner:hover {
	cursor: pointer;
	color: green;
} */