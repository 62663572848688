html {font-size:62.5%;font-family:"Helvetica Neue",sans-serif;line-height:1}
  
body{font-size:20px;background-color:#fff}

h1{font-size:30px}

h1.faq{font-size:30px;margin-bottom:1em}

h2{font-size:20px}

#registro-personal, #registro-de-otra-persona {font-size: 25px} /*Registro*/

h3{font-size:20px}

body{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;background-color:#fff}

a:link,a:hover,a:visited,a:active{/* color:#1d63ea; */text-decoration:none}

*.active {background-color: #fff;}

*,*::before,*::after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;margin:0;padding:0}

/**/ /**/ /**/

.container{
  display:grid;
  margin:auto;
  max-width:1170px;
  padding-left:15px;
  padding-right:15px;
  grid-template-rows: 115px minmax(400px, auto) auto; /**/
  grid-template-areas:"h n" /**/
                      "b b"
                      "g g"
}

.body {
  grid-area: b;
  display: grid;
  grid-template-areas:"a a a a a a a a a a a a"
                      "p p p p p i i i i i i i"
                      "k k k k k k k k k k k k"
                      "j j j j j j j j j j j j" 
                      "s s s s s s s s s s s s" 
                      "c c c c c c c c c c c c"
                      "t t t t t t t t t t t t" 
                      "v v v v v v v v v v v v"
                      "q q q q q q q q q q q q" 
                      "f f f f f f f f f f f f";
}

@media only screen and (max-width: 1070px){
  .container{
    grid-template-rows: 115px minmax(400px, auto) auto;
  }
}

@media only screen and (max-width: 992px){
  .container{
    grid-column-gap:0px;
    grid-template-rows:115px auto minmax(350px, auto) auto;
    //grid-template-areas:"h h" /**/
                        //"n n" 
                        //"b b"
                        //"g g"
  }

  .body {
    grid-area: b;
    grid-column-gap:0px;
    grid-template-areas:"a a a a a a a a a a a a"
                        "i i i i i i i i i i i i" 
                        "p p p p p p p p p p p p"
                        "k k k k k k k k k k k k"
                        "j j j j j j j j j j j j" 
                        "s s s s s s s s s s s s" 
                        "c c c c c c c c c c c c"
                        "t t t t t t t t t t t t" 
                        "v v v v v v v v v v v v"
                        "q q q q q q q q q q q q" 
                        "f f f f f f f f f f f f"
  }
}

@media only screen and (max-width: 320px){
  .container {
    padding-left: 5px;
    padding-right: 5px;
    margin: auto;
  }
}

.logo {grid-area:h; display:flex}

nav {
  grid-area:n;

  display:flex;
  justify-content:flex-end;
  align-items:center;

  .nav {
    display:flex;
    flex-direction: row;
    flex-wrap: nowrap;

    a, div {
      margin-left: 20px;
      color: #666;
    }
    a:hover, div:hover {
      color: black;
    }

    a.active {
      color: black;
    }
  }

  .mobileNav {

    button {
      border-radius: 100%;
      border: none;
      //padding: 5px;
      background-color: white;

      svg {
        font-size: 40px;
        color: rgb(60, 206, 105);
      }

      svg:hover {
        color: black;
      }
    }

    button:focus {
			outline: 0;
			svg {
        color: black;
      }
		}
  }
}

.logo{display:flex;width:181px;justify-content:flex-start;align-items:center;height:115px}

/*.logo{display:flex;width:100%;justify-content:flex-start;align-items:center;height:115px}*/

.logo>p{color:#000;display:flex;font-weight:bold;padding-bottom:1px}

.headerIcon{width:50px;height:50px;-webkit-clip-path:url(#shape);clip-path:url(#shape);margin-right:15px}

/*nav {width:924px;display:flex;justify-content:flex-end;align-items:center;height:115px}*/

//nav {width:100%;display:flex;justify-content:flex-end;align-items:center;/*height:115px*/}

/* nav>ul{color:#fff;display:flex;list-style-type:none}

nav>ul li {
  padding-left:50px;
  text-align:right;
  color: #000;
}

nav>ul li:first-child{padding-left:0px}

nav>ul li a:link,nav>ul li a:visited{text-decoration:none;color:rgba(0,0,0,0.6)}

nav>ul li a:hover,nav>ul li a:active{text-decoration:none;color:#000}

@media only screen and (max-width: 992px){
  
  //nav {margin-bottom: 1.5em}

  nav ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  nav ul li {
    padding-left: 0px;
    overflow: hidden;
    white-space: nowrap;
  }
} */

/*
@media only screen and (max-width: 375px){

  nav {position: fixed; top: 70px; z-index: 10; padding-bottom: 20px; background-color: #fff; width:325px;}

  .nav ul {justify-content: flex-start;}

  nav ul li {padding-right: 20px;}

  .container a {}

  .logo {position: fixed; top: -25px; z-index: 10; padding-right: 375px; background-color: #fff;}

  .container {background-color: #fff;}

} */

/* @media only screen and (max-width: 768px){
  nav ul {justify-content: flex-start;}
  nav ul li  {padding-right: 1em;}
}

@media only screen and (max-width: 320px){
  nav ul li:nth-child(1)  {padding-right: 0.5em;}
  nav ul li:nth-child(2)  {padding-right: 0.5em;}
  nav ul li:nth-child(3)  {padding-right: 0em;}
} */

/**/ /**/ /**/

a:active {color:#000}
/*
.appInfo{grid-area:i;display:flex;flex-wrap:wrap;padding-top:140px;padding-left:20px;padding-right:20px;align-content:flex-start}
*/
.appInfo{grid-area:i;display:flex;flex-wrap:wrap;padding-top:180px;padding-left:20px;padding-right:20px;align-content:flex-start}
/*
@media only screen and (max-width: 992px){.appInfo{padding-top:50px;justify-content:center}}
*/
@media only screen and (max-width: 992px){.appInfo{padding-top:120px;justify-content:center}}

.appIconShadow{display:flex;filter:drop-shadow(0px 5px 10px rgba(0,0,0,0.1)) drop-shadow(0px 1px 1px rgba(0,0,0,0.2))}

.appIconLarge{width:120px;height:120px;-webkit-clip-path:url(#shape120);clip-path:url(#shape120)}

.appNamePriceContainer{display:flex;flex:0 1 auto;flex-direction:column;align-items:flex-start;justify-content:center;margin-left:30px}

.appName{color:#000}

.appPrice{color:#000;font-weight:normal;margin-top:13px}

@media only screen and (max-width: 768px){.appNamePriceContainer{width:100%;margin-left:0px;align-items:center;justify-content:center}.appName{margin-top:30px;text-align:center}.appPrice{margin-top:13px;text-align:center}}

.appDescriptionContainer{font-size:25px;font-weight:normal;width:100%;align-items:flex-start;margin-top:45px;flex:0 1 auto;line-height:1.5}

.appDescription{color:#000}

@media only screen and (max-width: 992px){.appDescription{text-align:center}}

.downloadButtonsContainer{display:inline-block;margin-top:42px;filter:drop-shadow(0px 5px 10px rgba(0,0,0,0.1)) drop-shadow(0px 1px 1px rgba(0,0,0,0.2))}

@media only screen and (max-width: 992px){.downloadButtonsContainer{text-align:center}}

.playStore{height:75px;margin-right:24px}

@media only screen and (max-width: 992px){.playStore{margin-right:24px;margin-bottom:0px}}

@media only screen and (max-width: 528px){.playStore{margin-right:0px;margin-bottom:24px}}

.appStore{height:75px}
/*
.iphonePreview{grid-area:p;background-image:url("images/black.png");background-size:400px auto;background-repeat:no-repeat;margin-top:68px; padding-bottom: 20px;}
*/
.iphonePreview{grid-area:p;background-image:url("../images/black.png");background-size:400px auto;background-repeat:no-repeat;margin-top:108px; padding-bottom: 20px;}



.iphoneScreen{width:349px;-webkit-clip-path:url(#screenMask);clip-path:url(#screenMask);margin-left:26px;margin-top:23px}

@media only screen and (max-width: 1070px){

  .iphonePreview{background-size:370px auto}

  .iphoneScreen{
    width:322px;
    margin-left:24px;
    margin-top:22px}
}

@media only screen and (max-width: 992px){

  .iphonePreview{display:flex;background-size:260px auto;background-position:center 0;margin-top:47px;justify-content:center;}
  /*
  .iphonePreview{display:flex;background-size:260px auto;background-position:center 0;margin-top:47px;justify-content:center;padding-bottom:75px}
  */
  .iphoneScreen{width:226px;height:488px;-webkit-clip-path:url(#screenMask);clip-path:url(#screenMask);margin:0px;margin-top:17px}
}

.features{grid-area:c;display:flex;flex:0 1 auto;align-content:flex-start;justify-content:flex-start;flex-grow:1;flex-wrap:wrap;margin-top:93px}

.feature{display:flex;padding-top:63px;padding-left:15px;padding-right:15px;width:calc(100%/3)}

.feature:nth-child(-n+3){padding-top:0px}

.feature:nth-child(3n){padding-right:0px}

.feature:nth-child(3n+1){padding-left:0px}

.iconBack{color:#e6e6e6}

.iconTop{color:#1d63ea}

.socialIconBack{color:#e6e6e6}

.socialIconTop{color:#666}

.featureText{margin-left:18px}

.featureText>h3{color:#000}

.featureText>p{color:#666;margin-top:8px;line-height:1.5}

@media only screen and (max-width: 992px){.features{flex-grow:1;flex-direction:row;flex-wrap:wrap;margin-top:11px}.feature{display:flex;padding-top:41px;padding-left:15px;padding-right:15px;width:100%}.feature:nth-child(-n+3){padding-top:41px}.feature:nth-child(1){padding-top:0px}.feature:nth-child(3n){padding-right:15px}.feature:nth-child(3n+1){padding-left:15px}}

.featuresVideo{grid-area:v;display:flex;flex:0 1 auto;align-content:flex-start;justify-content:flex-start;flex-grow:1;flex-wrap:wrap;margin-top:93px}

video{height:100%;width:100%}

.video-container{overflow:hidden;position:relative;width:100%}

.video-container::after{padding-top:56.25%;display:block;content:''}

.video-container iframe{position:absolute;top:0;left:0;width:100%;height:100%}

@media only screen and (max-width: 992px){.featuresVideo{flex-grow:1;flex-direction:row;flex-wrap:wrap;margin-top:11px}.video{display:flex;padding-top:41px;padding-left:15px;padding-right:15px;width:100%}}

.featuresFAQ{grid-area:q;display:flex;flex:0 1 auto;align-content:flex-start;justify-content:center;flex-grow:1;flex-wrap:wrap;margin-top:63px}

.featuresFAQ h1.faq {text-align: left; margin-top: 20px;}

.sliderContainer {
  grid-area: k;
  line-height: 1.5;

  margin-top: 30px;
  margin-bottom: 30px;
  height: 330px;
}

.sliderContainerOrders {
  grid-area: j;
  line-height: 1.5;

  margin-top: 40px;
  margin-bottom: 30px;
  height: 330px;
}

.sponsors{
  grid-area:s;
  display:flex;
  justify-content: center; /**/
  flex:0 1 auto;
  flex-grow:1;
  flex-wrap:wrap;
  margin-top:93px
}

/*.sponsorsContent{align-items: center; align-content: center;}*/

.sponsorsContent {display: flex; justify-content: center;}

/*.sponsorsContent img:nth-child(1){width: 90%; height: 20%} /**/
.sponsorsContent img:nth-child(1){width: 456px; height: 95px} /**/
.sponsorsContent img:nth-child(2){width: 160px; height: 95px} /**/
.sponsorsContent img:nth-child(3){width: 205px; height: 95px} /**/

@media only screen and (max-width: 992px){
  .sponsors{
    /*flex-grow:1;*/
    flex-direction:row;
    flex-wrap:wrap;
    margin-top:11px
  }
  
  .sponsorsContent{
    display:flex;
    flex-wrap: wrap; /**/
    margin-top: 70px; /*margin-top: 20px;*/
    margin-bottom: 40px;
    width:100%;
    justify-content: center;
    align-content: center;
  }

  .sponsorsContent img {padding-top: 20px;}

  /*.sponsorsContent img:nth-child(1){width: 90%;}*/
  .sponsorsContent img:nth-child(1){width: 314px; height: 78px}

  /*.sponsorsContent img:nth-child(2){width: 50%;}*/
  .sponsorsContent img:nth-child(2){width: 174px; height: 124px}

  /*.sponsorsContent img:nth-child(3){width: 60%;}*/
  .sponsorsContent img:nth-child(3){width: 209px; height: 112px}
}

footer{display:flex;flex-wrap:wrap;justify-content:center;align-content:center}

/*footer{grid-area:f;display:flex;flex-wrap:wrap;justify-content:center;align-content:center}*/

.footerText{color:#666;display:block;line-height:1.5;width:100%;text-align:center;padding-top:70px;padding-bottom:70px}

.footerIcons{
padding-bottom:70px;
display:flex;

}

@media only screen and (max-width: 992px){

  .footerText{
    color:#666;
    display:block;
    line-height:1.5;
    width:100%;
    text-align:center;
    padding-top:54px;
    padding-bottom:61px}
    
  .footerIcons{
    padding-bottom:70px;
    display:flex;

  }
}

.hidden{display:none}


/**/ /**/ /**/

.answers ol li {
  list-style-position: inside;
  margin-top: 10px;
  margin-bottom: 10px;
}

/**/ /**/ /*added from master merge*/
.answers ul li {
  list-style-position: inside;
  margin-top: 10px;
  margin-bottom: 10px;

  a {
    color: grey;
  }

  a:hover {
    color: black;
  }
}

.answers ol li p {
  display: inline;
}

.answers ul li p {
  display: inline;
}
/**/ /**/ /**/

.answers ul li {
  list-style-position: inside;
}

.answers ol ul li {
  margin-left: 10px;
  line-height: 1;
}

.answers {
  line-height: 1.5;
}

/**/ /**/ /*added from master merge*/
.terminos-y-condiciones h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.politica h1 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.politica h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}
/**/ /**/ /**/

blockquote {
  background: #f9f9f9;
  border-left: 5px solid #ccc;
  margin: 0.5em 0px;
  padding: 0.5em 10px;
}

.vid {
  width: 854px;
  height: 480px;
}

@media only screen and (max-width: 992px){
  .vid {
    width: 100%;
    height: 204px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 320px){
  .vid {
    width: 90%;
  }
}

.questions h1 { margin-bottom: 1em;}

.questions>div>ul>li {
  list-style-position: outside;
  margin-left: 1em;
  line-height: 1.5;
}

.answers {grid-area: s;}

/**/

.footer {grid-area: g}

.politica h2 {font-size: 25px;}


.redirect {
  grid-area: s;
  /*display: flex;
  flex-direction: column;*/
  margin: 100px auto;
  text-align: center;
}

.circleContainer {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

button {
  padding: 5px;
}