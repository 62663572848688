.sliderContainer, .sliderContainerOrders {
    position: relative;
    height: 290px;
	margin-bottom: 40px;

	span.top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.navLink {
			color: green
		}

		.navLink:hover {
			color: black
		}
	}
	
	h3 {
		color: gray;
	}

	.slick-slider {
		position: absolute;
		/*height: 100%;*/
		width: 100%;
	}
	  
	.slick-slide {
		padding-right: 10px;
	}
	  
	.slick-list {
		margin-left: 30px;
		margin-right: 30px;
		padding: 10px;
	}
	  
	.slick-prev:before, .slick-next:before {
		color: black;
	}
	  
	.slick-next {
		right: 0px
	}
	  
	.slick-prev {
		left: 0px
	}
}
  
@media only screen and (max-width: 425px){

	.sliderContainer, .sliderContainerOrders {
		.slick-list {
			margin-left: 0px;
			margin-right: 0px;
		}
	
		.slick-prev:before, .slick-next:before {
			color: white;
			visibility: hidden;
		}
	
		.slick-next {
			right: 0px
		}
		
		.slick-prev {
			left: 0px
		}
	}
}
  
/*
.slick-list {
padding: 10px;
}

@media only screen and (min-width: 768px){

.slick-list {
	margin-left: 30px;
	margin-right: 30px;
}

.slick-prev:before, .slick-next:before {
	color: black;
}

.slick-next {
	right: 0px
}

.slick-prev {
	left: 0px
}

}
*/
  
/**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/

.sliderContainer, .sliderContainerOrders {
	.postPreview {
		width: 290px; /*width: 100%;*/
		
		.imgContainer {
			position: relative;
			margin-bottom: 9px;

			.photoPostContainer {
				position: relative;
				overflow: hidden;
				height: 150px;
				width: 250px;
				border-radius: 4px;

				#photoPost {
					position: absolute;
					
					/*top: 0%;
					left: 0%;*/
				
					height: auto; /*height: 200px;*/
					width: 250px; /*width: auto;*/
				
					/*https://stackoverflow.com/questions/18673900/how-to-center-and-crop-an-image-to-always-appear-in-square-shape-with-css*/
					/*position: absolute;*/
					left: -1000%;
					right: -1000%;
					top: -1000%;
					bottom: -1000%;
					margin: auto;
					min-height: 100%;
					min-width: 100%;
					/*border-radius: 4px;*/
				}
			}

			#photoProfile {
				position: absolute;
				top: 115px;
				right: 10px;
				height: 60px;
				width: 60px;
				border-radius: 100%;
				border: 2px solid #f9f9f9;
			
				/*overflow: visible; */
			}
		}

		.infoContainer {
			font-size: 15px;

			.skeleton {
				float: left;
			}

			.pricePerKg {
				color: #1a8f349a;
			}
		}
	}
}
