.aboutUsTitle {
    position: absolute;
}

.aboutUsContainer {
    grid-area: a;
    display: grid;
    grid-template-columns: 1fr 1fr;

    margin-top: 40px;

    .imgInfo {

        display: flex;
        flex-direction: row;

        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 20px;

        .imgContainer {
            position: relative;
            overflow: hidden;

            height: 120px;
            width: 120px;
            min-width: 120px;

            border-radius: 100%;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

            img {
                position: absolute;

                height: auto;
                width: 120px;

                left: -1000%;
                right: -1000%;
                top: -1000%;
                bottom: -1000%;
                margin: auto;
                min-height: 100%;
                min-width: 100%;
            }
        }

        .info {
            margin-left: 30px;
            line-height: 1.5;

            .nameEmail {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            h3 {
                color: grey;
            }

            p {
                font-size: 17px;
            }
        }
    }
}

@media only screen and (max-width: 768px){
    .aboutUsContainer {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 425px){
    .aboutUsContainer {
        .imgInfo {
            display: flex;
            flex-direction: column;

            .imgContainer {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 10px;
            }

            .info {
                margin-left: 0;
                
            }
        }
    }
}