.testimonials {
    grid-area: t;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 80px;
    margin-bottom: 30px;

    > * {
        margin-bottom: 10px;
    }
}