.banner {
    position: absolute;
    cursor: pointer;
    width: 100%;
    left: 0px;
    font-size: 15px;
    font-family: 'Ubuntu', sans-serif;
    background-color: #24294B;
    text-align: center;
    //color: white;
    padding: 10px 15px 10px 15px;

    a {
        color: white;
    }
}

.searchContainer {
    grid-area: a;
    margin-top: 90px;
    line-height: 1.5;

    h2.header {
        color: black;
        //line-height: 2;
        font-size: 35px;
        margin-bottom: 10px;
    }
}

/* .location-search-input {
    
} */

/* .infiniteScrollContainer {
    //width: 50%;
} */

.options .primary, .options .secondary {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    button {
        text-transform: none;
        //color: gray;
        color: rgb(133, 133, 133);
        border: 1px solid rgb(145, 144, 144);
        border-radius: 20px;
        margin-right: 5px;
        margin-bottom: 3px;
        padding: 8px 16px;
        font-size: 12px;
        line-height: 16px;

        span.option {
            //color: rgb(124, 124, 124);
            color: rgb(100, 100, 100);
            font-weight: 800;
        }
    }

    .disabled {
        color: lightgray;
        border: 1px solid rgb(206, 205, 205);
    }
}

#itemOptionImg {
    /* left: -1000%;
    right: -1000%;
    top: -1000%;
    bottom: -1000%; */
    /* margin: auto;
    min-height: 100%;
    min-width: 100%; */
    border-radius: 100%;
}

.optionInputs {
    display: flex;
    flex-direction: row;
}

.optionInputsOrder {
    display: flex;
    flex-direction: column;

    .MuiFormControl-root {
        margin-top: 5px;
    }
}

.itemOptionButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;

    button {
        border: none;
        background: white;
        font-weight: 600;
        color: gray;
        cursor: pointer;

        &:hover {
            color: rgb(80, 80, 80);
        }
    }
}

.priceOptionButtons, .volumeOptionButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    //margin-left: 5px;
    //margin-right: 5px;

    input {
        border: none;
        background: white;
        font-weight: 600;
        color: gray;
        cursor: pointer;

        &:hover {
            color: rgb(80, 80, 80);
        }
    }
}

.error {
    font-size: x-small;
    color: red;
    margin-top: 5px
}


.postSearchItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    margin: 5px;
    font-size: smaller;

    .left {

        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .nameImgStars {

            p {
                text-align: center;
                //font-size: 13px;
                //font-weight: 400;
                font-size: 15px;
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            img {
                height:100px;
                width: 100px;
                border-radius: 10px;
            }

            div > * {
                margin-left: 10px;
            }
        }
    
        .itemVolumePrice {

            margin-left: 20px;

            div {
                margin-bottom: 10px;
            }
             
            span {
                color: gray;
                font-size: 13px;
            }
        }
    }

    .distance {
        margin-top: auto;
        margin-bottom: auto;

        span {
            color: gray;
            font-size: 13px;
        }
    }
}

@media only screen and (max-width: 768px){
    .postSearchItem {    
            .itemVolumePrice {
                .volume {
                    font-size: 14px;
                }
                .price {
                    font-size: 14px;
                }
            }
        .distance {
            font-size: 14px;
        }
    }
}

@media only screen and (max-width: 320px){
    .postSearchItem {    
            .itemVolumePrice {
                .volume {
                    font-size: 12px;
                }
                .price {
                    font-size: 12px;
                }
            }
        .distance {
            font-size: 12px;
        }
    }
}



.location-search {
    .location-search-input {
        position: relative;

        padding: 5px;
        margin-top: 10px;
        margin-bottom: 10px;

        
    }

    .location-search-suggestions {
        position: absolute;
        z-index: 1;

        border: 1px solid darkgray;
        background-color: white;
        margin-right: 5px;
        /* padding-left: 10px;
        padding-right: 10px; */
        //width: 300px;
/* 
        border: 1px solid darkgray; */
        div {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

.spinnerContainer {
    width: 40px;
    height: 80px;

    margin: 70px auto;
}

.location-search {

    button {
        border: none;
        border-radius: 20px;
        background: white;
        font-weight: 600;
        font-size: 14px;
        text-transform: none;
        color: gray;
        
        cursor: pointer;

        &:hover {
            color: rgb(80, 80, 80);
            background-color: white;
        }
    }

    .search { 
        margin-left: 20px;
        
        button {

            padding: 8px 20px;
            background: rgb(60, 206, 105);
            color: white;

            &:disabled {
                background: white;
                color: lightgray;
            }
        }
    }

    @media only screen and (max-width: 425px){

        .search, .reset { 
            margin-left: 5px;

            /* @media only screen and (max-width: 325px){
                
                margin-left: 0px;

                button {
                    padding: 6px 8px;
                }
            } */
        }
    }

    @media only screen and (max-width: 325px){
                
        .search, .reset {
            margin-left: 0px;

            button {
                padding: 6px 6px;
            }
        }
    }
    

    /* button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
        padding: 0px;
        width: 20px;
    }

    button .MuiButton-label {
        padding: 0px;
    } */

    button.MuiButton-root {
        min-width: 0px;
        line-height: 1;
    }
}

.search-results-map {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.search-results {
    .info {
        color: gray;

        .epi {

            margin-top: 10px;
            margin-right: 10px;
            font-size: smaller;
            list-style-position: inside;

            /* span.sharopIcon {
                margin-left: 10px;
            } */
        }
    }
}

.map {
    //position: relative;
    //position: -webkit-sticky; /* Safari */
    position: sticky;
    position: -webkit-sticky;
    top: 8px; /* required */
    height: 100vh;
}

/* .MuiAppBar-root {
    width: 50px;
    height: 50px;
    border-radius: 100%;
} */

/* .MuiTab-textColorInherit {
    color: white;
    opacity: 1;
} */

.MuiTabs-indicator {
    visibility: hidden;
}

.visibleTab, .hiddenTab {
    position: absolute;
}

.hiddenTab {
    visibility: hidden;
}


.markerBalloon {

    img {
        position: absolute;
        top: -20px;
        left: -20px;
        border-radius: 100%;

        /* left: -1000%;
        right: -1000%;
        top: -1000%;
        bottom: -1000%;
        margin: auto;
        min-height: 100%;
        min-width: 100%; */
    }

    /* .balloon {
        position: absolute;
        //height: 30px;
        //width: 60px;
        z-index: 100;
        background-color: white;
        font-weight: 800;
        font-size: 15px;
        padding: 5px;

        .div .nameStars {
            display: flex;
            flex-direction: row;

        }
    } */
}

.balloon {
   
    color: gray;

    display: flex;
    flex-direction: row;

   .left {

        display: flex;
        flex-direction: column;
        margin-right: 10px;

        text-align: center;

        img {
            height: 50px;
            width: 50px;
            border-radius: 100%;
            margin-left: auto;
            margin-right: auto;
        }
   }

   .top p {
       display: flex;
       flex-direction: row;
       justify-content: space-between;
   }

   .top span {
       font-weight: 500;
       margin-right: 5px;
   }

   .top, .middle {
       margin-bottom: 5px;
   }

   .middle {
       color: gray;
       font-weight: 500;

       span {
           font-size: 14px;
           font-weight: normal;
       }
   }

   .bottom {
       color: black;
   }

   .nameStars {
        display: flex;
        flex-direction: row;

        .name {
            margin-right: 5px;
            color: gray;
        }
    }
}

.hover {
    border: 4px solid rgb(60, 189, 99);
    border-radius: 100%;
    z-index: 150;
}

.notHover {
    border: 2px solid rgb(47, 39, 119);
    border-radius: 100%;
}

.snackbar {
    position: relative;
    //bottom: 35px;
    background-color: white;
}

