.leaderboard {
    width: 100%;
    height: auto; /*height: 200px;height: 100%;*/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ 
    background-image: linear-gradient(155deg, #75A6E1, #22E2B6, #22E2B6);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-top: 60px;
    line-height: 1.5;

    .left {
        display: flex;
        flex-direction: column;
        color: white;
        margin-left: 60px; /*margin-left: 70px;*/
        margin-top: 20px;
        margin-bottom: 10px;

        .text .first{
            font-family: 'Ubuntu', sans-serif; /*font: "Ubuntu";*/
            font-weight: 500;
            font-size: 33px;
        }

        .text .second{
            font-family: 'Ubuntu', sans-serif; /*font: "Ubuntu";*/
            font-weight: 300;
            font-size: 28px;
        }

        .links {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 10px;

            .playStore, .appStore {
                height: 60px;
                width: auto;
            }
        }
    }

    .right {
        display: block;
        margin-left: auto;
        margin-right: auto;
        line-height: 0px;

        img {
            width: 250px;
            height: auto;
            margin: 0px 20px 0px 20px;
        }
    }
}

@media only screen and (max-width: 1024px){
    .leaderboard .left {
        margin-left: 40px; /*margin-left: 70px;*/
    }
}
  
@media only screen and (max-width: 900px){
    .leaderboard {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;

        .left {
            margin-left: auto;
            margin-right: auto;

            .links {
                justify-content: center;

                .playStore {
                    margin-right: 10px;
                }
            }

            .text {
                text-align: center;
            }
        }
    }

}
  
/*
@media only screen and (max-width: 768px){
    .leaderboard {
        width: 100%;
        height: auto;
        justify-content: center;
    }

    .leaderboard .left {
        margin-left: 0px;
    }

    .leaderboard .left .links {
        justify-content: center;
    }

    .leaderboard .left .text {
        text-align: center;
    }
}
*/
  
@media only screen and (max-width: 442px){
    .leaderboard .left .links img.playStore {
        margin-right: 0px;
        margin-bottom: 0px;
    }
}
  
@media only screen and (max-width: 425px){
    .leaderboard {
        width: 100%;
        /*height: 537px;*/

        .left {
            /*margin-left: 0px;*/
            max-width: 300px;
        } 
    }

    /*
    .leaderboard .left .links img.playStore {
      margin-bottom: 0px;
    }*/
}