@import "main";
@import "testimonials";
@import "promoBanner";
@import "promoPg";
@import "posts";
@import "leaderboard";
//@import "temp";
@import "descargar";
@import "carousel";
@import "search";
@import "buscar";
@import "equipo";


.downloadInstructions {
    padding-top: 40px;

    display: flex;
    justify-content: center;

    button {
        background-color: rgb(60, 206, 105);
        font-weight: bold;
        font-size: 20px;
        padding: 7px 12px;
        color: white;
        border-radius: 4px;
        border-color: rgb(60, 206, 105);
        cursor: pointer;

        display: flex;
        flex-direction: row;

        :first-child {
            font-size: 22px;
            margin-right: 10px;
        }
    }

    button:hover {
        outline: 0;
        text-shadow: 1px 2px grey;
    }

    button:focus {
        outline: 0;
        text-shadow: 1px 2px grey;
    }
}