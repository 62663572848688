.buscar {

	grid-area: a;
	margin-top: 90px;

	.headerLocation-search {
		margin-bottom: 5px;

		h1 {
			line-height: 1.5;
		}

		.location-search {
			.inputs {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}

			.location-search-suggestions {
				z-index: 1020;
			}
		}
	}
	

	.buscarMapContainer {
		position: relative;
	}
	
	.filterButton {
		/* position: absolute;
		top: 10px;
		left: 10px;
		z-index: 1000;

		border-radius: 100%; */

		position: relative;

		button {
			position: absolute;
			//top: 10px;
			margin-top: 10px;
			//left: 10px;
			margin-left: 10px;
			z-index: 1000;

			border-radius: 100%;
			padding: 8px 10px;

			background-color: white;
			color: rgb(60, 206, 105);
			//font-size: 8px;
			//font-weight: 500;
			cursor: pointer;
			border: 1px solid rgb(230, 229, 229);
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

			svg {
				font-size: 18px;
				font-weight: 600;
			}
		}

		button:focus {
			outline: 0;
			//box-shadow: 0 0 3pt 2pt rgb(94, 158, 214);
			box-shadow: 0 0 3pt 2pt grey;
		}

		.indicator {
			height: 10px;
			width: 10px;
			background-color: rgb(60, 206, 105);
			border-radius: 100%;
			z-index: 1001;

			position: absolute;
			top: 10px;
			left: 40px;
		}
	}

	.searchButton {
		button {

			position: absolute;
			z-index: 1000;
			margin-left: auto;
			margin-right: auto;
			margin-top: 10px;
			left: 0;
			right: 0;
			text-align: center;

			border-radius: 20px;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 5px;
			padding-bottom: 5px;

			background-color: white;
			color: rgb(60, 206, 105);
			font-size: 15px;
			text-transform: none;
			font-weight: 500;
			cursor: pointer;
			border: 1px solid rgb(230, 229, 229);
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			
		}

		button:focus {
			outline: 0;
			//box-shadow: 0 0 3pt 2pt rgb(94, 158, 214);
			box-shadow: 0 0 3pt 2pt grey;
		}

		button:disabled {
			color: grey;
			cursor: default;
		}

		.circularProgress {
			position: absolute;
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
			text-align: center;
			color: rgb(60, 206, 105);
		}
	}

	.loadingScreen {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 980;
		background-color: rgba(128, 128, 128, 0.5);

		.circularProgress {
			position: absolute;
			z-index: 990;
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
			top: 40%;
			text-align: center;
			color: rgba(60, 206, 105, 1.0);
		}
	}

	.crossHair svg {
		position: absolute;
		z-index: 1000;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		top: 48.5%;
		//bottom: 50%;
		text-align: center;
	}

	img.iconImgContainer  {
		height: 20px;
		width: 20px;
		border-radius: 100%;

		position: absolute;
		left: -10px;
		top: -10px;

		cursor: pointer;
	}

	.liveIndicator {
		height: 5px;
		width: 5px;
		background-color: rgb(68, 235, 118);
		border-radius: 100%;
		//z-index: 1001;

		position: absolute;
		top: -10px;
		left: 5px;
		
	}

    .circle {
        height: 10px;
		width: 10px;
		border-radius: 100%;
		opacity: 0.8;

		position: absolute;
		left: -5px;
		top: -5px;

		background-color: rgb(2, 154, 255);
		border: 1px solid white;
	}

	.circleSearchArea {
		border-radius: 100%;

		position: absolute;

		border: 2px dashed rgba(128, 128, 128, 0.6);
	}
}

.filterDialog {
	.filterRolesAndTitle {

		.title {
			margin-bottom: 10px;
		}

		margin-bottom: 10px;

		.filterRoles {

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			margin-bottom: 5px;

			.filterRole {

				display: flex;
				flex-direction: row;
				align-items: center;

				cursor: pointer;

				background-color: rgb(207, 207, 207);
				border-radius: 20px;
		
				font-size: 15px;
		
				margin-bottom: 4px;
				margin-right: 4px;

				padding: 1px 10px 1px 3px;

				img {
					width: 20px;
					height: 20px;
					border-radius: 100%;
		
					margin: 3px;
				}

				p {
					margin-left: 4px;
					
				}

			}

			.true {
				background-color: #7edc7a;
			}
		}
	}

	.filterItemsAndTitle {

		.filterItems {

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			margin-bottom: 5px;

			.filterItem {

				display: flex;
				flex-direction: row;
				align-items: center;

				cursor: pointer;

				background-color: rgb(207, 207, 207);
				border-radius: 20px;
		
				font-size: 15px;
		
				margin-bottom: 4px;
				margin-right: 4px;

				padding: 1px 10px 1px 3px;
				
				img {
					width: 20px;
					height: 20px;
					border-radius: 100%;
		
					margin: 3px;
				}

				p {
					margin-left: 4px;
					
				}
			}

			.true {
				background-color: #7edc7a;
			}
		}
	}
}

.onlyLive {
	margin-top: 20px;
	margin-bottom: 20px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;

	align-items: center;

	p {
		font-size: 15px;
		display: flex;
		flex-direction: row;
		align-items: center;

		svg {
			color: #ffd721;
			height: 20px;
			width: 20px;
			margin-right: 10px;
		}
	}

	/* :checked {
		color: rgb(60, 206, 105);
	} */


	.MuiSwitch-root .Mui-checked {
		color:rgb(60, 206, 105);
		
	}

	.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
		background-color: rgb(60, 206, 105);
	}

}

.resetFilter {
	display: flex;
	justify-content: center;
	text-align: center;

	margin-top: 30px;
	margin-bottom: 20px;
	color:red;
	font-size: 15px;

	p:hover {
		cursor: pointer;
		color: black;
	}
}

