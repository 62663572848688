.promoHeadline {
    width: 100%; /*width: 130%;*/
    height: auto; /*height: 200px;height: 100%;*/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ 
    min-height: 500px;
    /*background-image: linear-gradient( 90deg, rgba(253,83,146,0.99608) 0%, rgb(248,111,100) 100%);*/
    display: grid;
    grid-template-columns: 1fr 1fr;
    line-height: 1;
    /*display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;*/
    /*margin-left: -150px;*/
    /*position: absolute;
    left: 0px;*/
    /*width: 100vw;
	margin-left: auto;*/
	
	.left {
		display: flex;
		flex-direction: column;
		color: white;
		margin-left: 40px;
		margin-top: auto;
		margin-bottom: auto;

		.text {

			.first{
				font-family: 'Ubuntu', sans-serif; /*font: "Ubuntu";*/
				font-weight: 500;
				font-size: 60px;
			}

			.second{
				font-family: 'Ubuntu', sans-serif; /*font: "Ubuntu";*/
				font-weight: 300;
				font-size: 40px;
				margin-top: 20px;
			}

			.third {
				font-family: 'Ubuntu', sans-serif; /*font: "Ubuntu";*/
				font-weight: 500;
				font-size: 28px;
				margin-top: 20px;
				margin-bottom: 20px;
			}
		}

		.countdown {
			font-family: 'Ubuntu', sans-serif;
			font-size: medium;
		}
	}

	.right {
		display: block;
		/*margin-left: auto;
		margin-right: auto;*/
		line-height: 0px;
		margin-top: auto;
		margin-bottom: auto;

		img {
			width: 400px;
			height: auto;
			/*position: absolute;
			top: 0px;
			right: 0px;*/
		}
	}
}
  
.background {
    position: absolute;
    background-image: linear-gradient( 90deg, rgba(253,83,146,0.99608) 0%, rgb(248,111,100) 100%);
    z-index: -1;
    width: 100vw;
    height: 500px;
    left: 0px;
}
  
@media only screen and (max-width: 1024px){
    .promoHeadline {
      position: relative;
    }
  
    .background {
      height: 100%;
      left: -15px;
    }
}

@media only screen and (max-width: 850px){
	.promoHeadline  .left .text .first{
		font-size: 50px;
	}
}

@media only screen and (max-width: 600px){
	.promoHeadline  .left .text .first{
		font-size: 40px;
	}
}
  
@media only screen and (max-width: 320px){
    .background {
      left: -5px;
    }
}

  
@media only screen and (max-width: 768px){
    .promoHeadline .left .countdown > div > div {
      justify-content: center;
      text-align: center;
	}
	
	.promoHeadline .left .countdown {
		text-align: center;
	}
}
  
@media only screen and (max-width: 768px){
    .promoHeadline {
		display: grid;
		grid-template-columns: 1fr;
		width: 100%;
		height: auto; /*height: 378px;height: 100%;*/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ /**/ 
		justify-content: center;
	  
	  	.left {
			margin-left: 0px;
			margin-top: 50px;
		}
		  
		.button {
			margin-left: auto;
			margin-right: auto;
		}

		.right {
			margin-left: auto;
			margin-right: auto;
			margin-top: 30px;
			margin-bottom: 20px;
		}

		.left {
			.links {
				justify-content: center;
			}

			.text {
				text-align: center;
			}
		}

		.MuiButtonBase-root {
			margin-left: auto;
			margin-right: auto;
		}
    }
}
  
@media only screen and (max-width: 425px){
    .promoHeadline {
		width: 100%;
		/*height: 537px; height: 100%;*/

		.left {
			margin-left: 0px;
			/*max-width: 300px;*/
		}

		.right img {
			width: 300px;
			height: auto;
		}
    }
}
  
.activity {
    margin-top: 30px;
	color: rgba(253,83,146,0.99608);
	
	.items {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.paperContainer {
			width: 50%;
		}
	}
}
  
@media only screen and (max-width: 768px){
    .activity .items .paperContainer {
      width: 100%;
    }
}
  
.steps {
	color: rgba(253,83,146,0.99608);
	
	img {
		width: 200px;
	}

	.stepOdd, .stepEven {
		/*display: grid;
		grid-template-columns: 1fr 1fr;*/
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 10px;

		> * {
			width: 50%;
		}
	}

	.stepOdd {
		flex-wrap: wrap-reverse;

		/*
		.right .number {
			font-size: 40px;
			font-weight: 500;
		}
		*/
	}

	.stepEven {
		flex-wrap: wrap;
	}

	.left img {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.right {
		display: block;
		margin-top: auto;
		margin-bottom: auto;
		width: 50%; /**/
		min-width: 300px;

		ol.one li::before {
			content: "1. ";
			color: rgba(253,83,146,0.99608);
			font-size: 30px;
			font-weight: 500;
		}

		ol.two li::before {
			content: "2. ";
			color: rgba(253,83,146,0.99608);
			font-size: 30px;
			font-weight: 500;
		}

		ol.three li::before {
			content: "3. ";
			color: rgba(253,83,146,0.99608);
			font-size: 30px;
			font-weight: 500;
		}

		ol.four li::before {
			content: "4. ";
			color: rgba(253,83,146,0.99608);
			font-size: 30px;
			font-weight: 500;
		}
	}

	ol {
		list-style: none;
		counter-reset: my-awesome-counter;
		/*list-style-position: outside;*/

		li {
			counter-increment: my-awesome-counter;
			color: black;
		}
	}
}
  
@media only screen and (max-width: 425px) {
    .steps .left {
      width: 100%;
    }
}
