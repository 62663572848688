.descargarContainer {
	/*background-color: #14233c;*/
	/*margin-bottom: 1rem;*/
	/*width: 50%;*/

	display: grid;
	grid-template-columns: 1fr 1fr;

	.backgroundDescargar {
		position: absolute;
		background-color: #14233c;
		z-index: -1;
		width: 100vw;
		height: 432px;
		left: 0px;
	}

	.androidPreview {
		margin-left: auto;
		margin-right: auto;
		margin-top: 20px;
		margin-bottom: auto;

		img {
			width: 200px;
		}
	}
}

@media only screen and (max-width: 1065px){
	.descargarContainer {
		position: relative;

		.backgroundDescargar {
			height: 100%;
			left: -15px;
		}
	}
}
  
@media only screen and (max-width: 320px){
    .descargarContainer .backgroundDescargar {
      left: -5px;
    }
}
  
@media only screen and (max-width: 768px){
    .descargarContainer {
		/*grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;*/
		display: flex;
		flex-direction: column;
    }
}
